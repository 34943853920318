// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyD0HVei6vbbGT60cVvf0yLkR6jSsO4Iyvc",
  authDomain: "indbvknew6.firebaseapp.com",
  databaseURL: "https://indbvknew6-default-rtdb.firebaseio.com",
  projectId: "indbvknew6",
  storageBucket: "indbvknew6.appspot.com",
  messagingSenderId: "449274544402",
  appId: "1:449274544402:web:72b7a12e8ccf12cebeb3cb",
  measurementId: "G-2WT6D0XZHP"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };